.admin_navi.navbar.navbar-inverse {
  background: #20a3f0;
  border: 0px;
  border-radius: 0px;
}

.admin_navi.navbar-inverse .navbar-brand {
  color: #fff;
}

.admin_navi.navbar-inverse .navbar-nav > li > a,
.admin_navi.navbar-inverse .navbar-text {
  color: #fff;
  text-transform: uppercase;
}
.admin_navi .navbar-brand > img {
  display: block;
  height: 30px;
}

.admin_navi .navbar-right {
  float: right !important;
  margin-right: 0 !important;
}

.right_div_delete button {
  background: none;
  border: 0px;
}

.admin_navi .navbar-right.nav > li button {
  background: #f5f5f5;
  border: 0px;
  border-radius: 0;
  color: #20a3f0;
  padding: 20px 25px;
  font-size: 14px;
  font-weight: normal;
}

.admin_navi .navbar-right.nav > li .fa.fa-user {
  line-height: 52px;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
}

.btn.btn-primary {
  background: #20a3f0;
  border: 0px;
  padding: 10px 20px;
  text-transform: uppercase;
}

.form-control {
  height: 42px;
  border-bottom: 2px #20a3f0 solid;
}

.ql-editor.ql-blank {
  min-height: 200px;
  border-bottom: 2px #20a3f0 solid;
}

input[type="file"] {
  display: block;
  background: #20a3f0;
  padding: 10px;
  color: #fff;
}

.card h5 {
  color: #000;
  font-size: 16px;
}

.card h5 span {
  color: #20a3f0;
  font-size: 16px;
}
.card h2 {
  color: #20a3f0;
  font-size: 20px;
}
.card h2 a:hover {
  text-decoration: none;
}
.blogs_admin_list {
  width: 100%;
  float: left;
  border-bottom: 1px #ccc solid;
  padding-bottom: 20px;
  margin-top: 20px;
}

.card {
  width: 90%;
  float: left;
  padding-bottom: 20px;
  margin-top: 20px;
}

.card .blog_img {
  width: 100%;
  float: left;
}

.card .blog_img img {
  width: 100%;
  margin: 20px 0;
}
h3.admin_list_heading {
  color: #20a3f0;
  text-transform: uppercase;
  position: relative;
  font-size: 20px;
}
h3.admin_list_heading::after {
  position: absolute;
  content: "";
  background: #20a3f0;
  width: 50px;
  height: 4px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.right_div_delete {
  width: auto;
  float: left;
  margin-top: 20px;
}

.right_div_delete .fa.fa-trash {
  background: red;
  padding: 10px 15px;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 10px;
}

.right_div_delete .fa.fa-trash:hover {
  opacity: 0.7;
}

.right_div_delete .fa.fa-pencil-square-o {
  background: #20a3f0;
  padding: 10px 12px 10px 15px;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 10px;
}
.right_div_delete .fa.fa-pencil-square-o:hover {
  opacity: 0.7;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  min-width: 100px;
}

@media (max-width: 980px) {
  .nav-item.admin_nav {
    float: left;
  }
}

.for_description_div {
  width: 100%;
  float: left;
}
.for_description_div img {
  width: 100%;
  margin: 10px 0;
  float: left;
}
.for_description_div_Icon img {
  width: 10%;
  margin: 10px 0;
}
.add_new_blog_div h3 {
  font-size: 18px;
}
.ql-editor img {
  width: 100%;
  max-width: 400px !important;
}
.ql-toolbar svg {
  width: 100%;
  margin: 0 auto 50px;
  display: block;
}

.admin_panel {
  width: 100%;
  float: left;
  background: #fff;
  /* z-index: 999999; */
  position: relative;
}
