body {
  background-color: #fff;
}

.text-danger {
  color: rgb(255, 30, 30);
}

.upload-btn {
  padding: 20px 40px;
  border: none;
  color: #fff;
  background-color: #20a3f0;
  border-radius: 10px;
}

.w-30 {
  width: 20%;
}

.ql-editor {
  min-height: 200px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-3 {
  margin-bottom: 10px;
}

.mr-date {
  margin-left: 15px;
}

.url {
  display: inline-block;
  width: 100%;
  /* max-width: 80% !important; */
  overflow-x: scroll !important;
  white-space: nowrap;
}

/* .blog-img {
  width: 70% !important;
  height: 60% !important;
}
.blog-img img {
  height: 100% !important;
} */

.profile-link {
  display: contents !important;
}
.reset-form {
  margin: auto;
  max-width: 50%;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .w-30 {
    width: 35%;
  }

  .reset-form {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .w-30 {
    width: 100%;
  }

  .reset-form {
    max-width: 100%;
  }
}
