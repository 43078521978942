.modal-header {
  text-align: center;
  padding: 0;
}

.modal-btn-container {
  display: flex;
  justify-content: center;
}

.modal-btn-container button {
  padding: 4px 8px;
  margin: 4px;
  border: none;
  color: #fff;
  border-radius: 5px;
}
.panel-footer,
.panel-title {
  padding: 10px !important;
}
.btn-cancel {
  background-color: rgb(60, 211, 60);
}
.btn-delete {
  background-color: rgb(255, 36, 36);
}
