@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_wlxdr.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(https://fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr.ttf) format('truetype');
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 300;
  }
  
  .wrapperlogin {
      background: #50a3a2;
      background: linear-gradient(to bottom right, #3c8ebe 0%, #45ff93 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
  }
  .wrapperlogin.form-success .containerlogin h1 {
    -webkit-transform: translateY(85px);
            transform: translateY(85px);
  }
  .containerlogin {
      max-width: 100%;
      width: 400px;
      margin: 0 auto;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 99999 !important;
  }
  .containerlogin h1 {
    font-size: 40px;
    transition-duration: 1s;
    transition-timing-function: ease-in-put;
    font-weight: 200;
  }
  .containerlogin form {
    padding: 20px 0;
    position: relative;
    z-index: 2;
  }
  .containerlogin form input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.2);
    width: 250px;
    border-radius: 3px;
    padding: 10px 15px;
    margin: 0 auto 10px auto;
    display: block;
    text-align: center;
    font-size: 18px;
    color: white;
    transition-duration: 0.25s;
    font-weight: 300;
  }
  .containerlogin form input:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .containerlogin form input:focus {
    background-color: white;
    width: 300px;
    color: #53e3a6;
  }
  .containerlogin form button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: 0;
    background-color: white;
    border: 0;
    padding: 10px 15px;
    color: #000;
    border-radius: 3px;
    width: 250px;
    cursor: pointer;
    font-size: 18px;
    transition-duration: 0.25s;
  }
  .containerlogin form button:hover {
    opacity: 0.7;
  }
  .btn-flat.waves-effect {
	color: #fff;
	font-size: 16px !important;
}

.grey-text.text-darken-1 {
	color: #fff;
	font-size: 16px;
}

.grey-text.text-darken-1 a {
	color: #000;
}
  .wrapperlogin .bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .wrapperlogin .bg-bubbles li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -160px;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;
    transition-timing-function: linear;
  }
  .wrapperlogin .bg-bubbles li:nth-child(1) {
    left: 10%;
  }
  .wrapperlogin .bg-bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 17s;
            animation-duration: 17s;
  }
  .wrapperlogin .bg-bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
  }
  .wrapperlogin .bg-bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
            animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .wrapperlogin .bg-bubbles li:nth-child(5) {
    left: 70%;
  }
  .wrapperlogin .bg-bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .wrapperlogin .bg-bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
  }
   .wrapperlogin .bg-bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
  }
   .wrapperlogin .bg-bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 40s;
            animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .wrapperlogin .bg-bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
            animation-delay: 11s;
  }
  @-webkit-keyframes square {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-700px) rotate(600deg);
              transform: translateY(-700px) rotate(600deg);
    }
  }
  @keyframes square {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-700px) rotate(600deg);
              transform: translateY(-700px) rotate(600deg);
    }
  }
  
  .wrapperlogin  .logo_panel {
      width: 100%;
      float: left;
      text-align: center;
      margin: 20px 0 0 0;
      
      padding-bottom: 20px;
  }
  .wrapperlogin  .logo_panel img {
      width: 100%;
      max-width: 100px;
  }
  
  .wrapperlogin  .set_password {
      color: #000;
      font-weight: bold;
  }
  .wrapperlogin  .set_password:hover{color: #fff;}