.grmevn,
.fqjKYR,
.gbfYBK {
  max-height: unset !important;
}

.jRtJxz {
  padding: 4px 16px 4px 16px !important;
}

.gNKLQI,
.kjAbkj,
.gaqLCA {
  padding-left: 6px !important;
}

.jTCPxO {
  padding-left: 6px !important;
}

.table-header {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.add-btn {
  height: 40px;
  width: 140px;
  background-color: #20a3f0;
  color: #fff;
  border: none;
  /* margin-top: 10px; */
}

.preview {
  width: 200px;
  height: 150px;
  margin-top: 20px;
}

.table-search input {
  padding-right: 40px;
}

.table-search img {
  margin-top: 6px;
}
